import React from 'react';
import { navigate } from 'gatsby';
import Cookies from 'universal-cookie'

// Components
import cn from 'classnames';

// Styles
import styles from '../styles/scss/components/cardAdblock.module.scss';
import Imgix from "react-imgix"

import "lazysizes";
import "lazysizes/plugins/parent-fit/ls.parent-fit";

const cardAdblock = ({
    adImage = '',
    cta,
    ctaText,
    bodyText,
    title,
    subtitle,
    target,
    backgrounds,
    isModalEnabled = false,
    onloadmodal = false,
    setShowModal,
    setModalIndex,
    index,
    desktopPosition = 'Top',
    mobilePosition = 'Above CTA',
    theming = 'Top',
    swapTitleSubtitle = false,
}) => {
    const cookies = new Cookies();
    const modalAlreadyShown = cookies.get(`modal-${index}`)

    const getCustomSubtitled = (subtitle) => {
        let result = subtitle;
        if (subtitle.includes("TM")) {
            result = (
                <span>{subtitle.replace("TM", "")}<sub>&trade;</sub></span>
            )
        }
        return result;
    }
    const specialBlock = '/products/specialist-degreaser-ez-pods';

    return (
        <div
            className={cn(!onloadmodal ? styles[`newContainer_${theming}`] : styles.newContainerOnLoadModal)}
        >
            {
                theming === 'Top' &&
                <>
                    {
                        desktopPosition === 'Top' &&
                        <LogoSection
                            theme={'Top'}
                            adImage={adImage}
                            desktopPosition={desktopPosition}
                            mobilePosition={mobilePosition}
                        />
                    }
                    {(title || subtitle) && (
                        <>
                            {
                                subtitle && title &&
                                <p className={title ? styles.subtitle : styles.altTitle}>
                                    {subtitle &&
                                        <span className={isModalEnabled ? styles.defaultTitleCont : styles.defaultTitle}>
                                            {getCustomSubtitled(subtitle)}</span>
                                    }
                                    {title && title}
                                </p>
                            }
                            {
                                (title && !subtitle) &&
                                <p className={title ? styles.subtitle : styles.altTitle}>
                                    <span className={isModalEnabled ? styles.defaultTitleCont : styles.defaultTitle}>
                                        {title}
                                    </span>
                                </p>
                            }
                        </>

                    )}

                    {bodyText &&
                        <div className={cta === specialBlock ? styles.bodyText : styles.bodyTextComplete} dangerouslySetInnerHTML={{
                            __html: bodyText,
                        }} />

                    }
                    {
                        desktopPosition === 'Above Arc' &&
                        <LogoSection
                            theme={'Above Arc'}
                            adImage={adImage}
                            desktopPosition={desktopPosition}
                            mobilePosition={mobilePosition}
                        />
                    }


                    {(!isModalEnabled && cta && ctaText) && (
                        <a
                            className={bodyText ? styles.redButtonLeft : styles.redButtonRight}
                            href={cta}
                            target={target}> {ctaText}</a>
                    )}
                </>
            }

            {
                theming === 'Bottom' &&
                <>
                
                    {(title || subtitle) && (swapTitleSubtitle === false) && (
                        <>
                            <p className={styles.bottom_title}>
                                {title && title}
                            </p>
                            <p className={styles.bottom_subtitle}>
                                {subtitle && getCustomSubtitled(subtitle)}
                            </p>
                        </>
                    )}
                    {(title || subtitle) && (swapTitleSubtitle === true) && (
                        <>
                            <p className={styles.bottom_subtitle}>
                                {subtitle && getCustomSubtitled(subtitle)}
                            </p>

                            <p className={styles.bottom_title}>
                                {title && title}
                            </p>
                        </>
                    )}

                    {bodyText &&
                        <div className={cta === specialBlock ? styles.bodyText : styles.bodyTextComplete} dangerouslySetInnerHTML={{
                            __html: bodyText,
                        }} />

                    }

                    <LogoSection
                        theme={'Bottom'}
                        adImage={adImage}
                        desktopPosition={desktopPosition}
                        mobilePosition={mobilePosition}
                    />

                    {(!isModalEnabled && cta && ctaText) && (
                        <a
                            className={styles.bottom_cta}
                            href={cta}
                            target={target}> {ctaText}</a>
                    )}
                </>
            }


            {
                onloadmodal && (
                    <div
                        aria-hidden
                        role="button"
                        onKeyDown={() => {
                            if (!modalAlreadyShown && onloadmodal) {
                                cookies.set(`modal-${index}`, true, { path: '/' });
                            }
                            navigate(cta)
                        }}
                        onClick={() => {
                            if (!modalAlreadyShown && onloadmodal) {
                                cookies.set(`modal-${index}`, true, { path: '/' });
                            }
                            navigate(cta)
                        }}
                        className={title ? styles.redButtonLeft : styles.redButtonRight}
                       
                        target={target}> {ctaText}</div>
                )
            }

            {isModalEnabled && !onloadmodal && (
                <div
                    aria-hidden
                    role="button"
                    onKeyDown={() => {
                        setShowModal(true)
                        setModalIndex(index)
                    }}
                    onClick={() => {
                        setShowModal(true)
                        setModalIndex(index)
                    }}
                    className={title ? styles.redButtonLeft : styles.redButtonRight}
                    
                    target={target}> {ctaText}</div>
            )}

            <div className={cn(styles.desktopAD)}>
                <Imgix
                    className={styles.desktopAD_image}
                    src={backgrounds.desktop.url}
                    htmlAttributes={{alt: ''}}
                />
            </div>

            <div className={cn(styles.tabletAD)}>
                <Imgix
                    className={styles.tabletAD_image}
                    src={onloadmodal ? backgrounds.mobile.url : backgrounds.tablet.url} htmlAttributes={{alt: ''}} />
            </div>

            <div className={cn(styles.mobileAD)}>
                <Imgix
                    className={styles.mobileAD_image}
                    src={backgrounds.mobile.url} htmlAttributes={{alt: ''}} />
            </div>
        </div >
    );
}

function LogoSection({ adImage, desktopPosition, mobilePosition, theme }) {

    return (
        <>
            {(adImage && theme === 'Top' && (desktopPosition === 'Top')) &&
                <Imgix
                    src={adImage.url}
                    htmlAttributes={{alt: 'Ad block Logo'}}
                    className={styles.adProductImageDesktopTop}
                />
            }

            {(adImage && (desktopPosition === 'Above Arc')) &&
                <div className={styles.adProductImageDesktopWrapper}>
                    <Imgix
                        src={adImage.url}
                        alt="Ad block Logo"
                        width={300}
                        className={`lazyload ${styles.adProductImageDesktop1}`}
                        attributeConfig={{
                            src: "data-src",
                            srcSet: "data-srcset",
                            sizes: "data-sizes",
                        }}
                        htmlAttributes={{
                            alt: 'Ad block Logo'
                        }}
                    />
                </div>
            }

            {(adImage && (mobilePosition === 'Above CTA')) &&
                <img className={styles.adProductImage} sizes="100vw" src={adImage.url} alt={'Ad block Logo'} />
            }

            {(adImage && (mobilePosition === 'Above Arc(To the right)')) &&
                <div className={styles.adProductImageMobileAboveArcRight}>
                    <img className={styles.adProductImage} sizes="100vw" src={adImage.url} alt={'Ad block Logo'} />
                </div>
            }
        </>
    );
}

export default React.memo(cardAdblock);
