import React, { Component } from 'react';
import { Row, Col } from 'react-grid-system';
import styles from '../styles/scss/components/cardBlog.module.scss';
import Button from './button';

export default class Index extends Component {

    render() {
        const { title, image, link } = this.props;
        return (
            <Row className={styles.boxWrap}>
                <Col xs={12} md={12} className={styles.boxWrapImage}>
                    <div style={{ backgroundImage: `url(${image})`}} className={styles.boxImage} >
                        <h3 className={styles.cardTitle}>{title}</h3>
                        <Button
                            text="READ MORE"
                            url={`/article/${link}`}
                            className={styles.button}
                            target="_self"
                            newBlue
                            pdf
                            rel="noopener noreferrer"
                        />
                    </div>
                </Col>
            </Row>
        );
    }
}
