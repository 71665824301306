import React from 'react';
import CardVideo from './cardVideo';
import CardImage from './cardImage';
import CardDetailed from './cardDetailed';
import CardProduct from './cardProduct';
import CardBlog from './cardBlog';
import RelatedCardBlog from './relatedCardBlog';
import RelatedProductCardBlog from './relatedProductCardBlog';
import CardAdblock from './cardAdblock';

const Card = ({ type, ...props }) => {
  switch (type) {
    case "video":
      return <CardVideo {...props} />
    case "image":
      return <CardImage {...props} />
    case "detailed":
      return <CardDetailed {...props} />
    case "product":
      return <CardProduct {...props} />
    case "ratedProduct":
      return <CardProduct {...props} withoutAnchor />
    case "blog":
      return <CardBlog {...props} />
    case "adblock":
      return <CardAdblock {...props} />
    case "relatedBlog":
      return <RelatedCardBlog {...props} />
    case "relatedProductBlog":
      return <RelatedProductCardBlog {...props} />
    default:
      return null;
  }
}

export default Card;
