import React from "react"
import styles from "../styles/scss/components/quickModal.module.scss"
import Modal from 'react-responsive-modal'
import Cookies from 'universal-cookie'

const QuickModal = ({ showModalIndex, setModalIndex, setShowModal, children, index, onloadmodal = false, ...props }) => {
    const cookies = new Cookies();
    const modalAlreadyShown = cookies.get(`modal-${index}`)

    const closeIcon = (
        <svg width="39" height="39" viewBox="0 0 57 61" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g filter="url(#filter0_d_4025_4631)">
                <path d="M34.5293 38.1872C34.8122 38.47 34.8122 38.7529 34.5294 39.0357L32.0686 41.4965C31.7858 41.7793 31.5029 41.7793 31.2201 41.4965L24.941 35.2173C24.8278 35.1042 24.7147 35.1042 24.6016 35.2173L18.3649 41.454C18.082 41.7369 17.7992 41.7369 17.5164 41.454L15.0556 38.9933C14.7728 38.7105 14.7728 38.4276 15.0556 38.1448L21.2923 31.9081C21.4054 31.795 21.4054 31.6818 21.2923 31.5687L14.9708 25.2471C14.6879 24.9643 14.6879 24.6815 14.9708 24.3986L17.4315 21.9379C17.7144 21.655 17.9972 21.655 18.28 21.9379L24.6016 28.2594C24.7147 28.3726 24.8278 28.3726 24.941 28.2594L31.3049 21.8955C31.5878 21.6126 31.8706 21.6126 32.1535 21.8955L34.6142 24.3562C34.897 24.639 34.897 24.9219 34.6142 25.2047L28.2502 31.5687C28.1371 31.6818 28.1371 31.795 28.2502 31.9081L34.5293 38.1872Z" fill="#FFF200" />
            </g>
            <circle cx="25" cy="32" r="22.5" stroke="#FFF200" strokeWidth="3" />
            <defs>
                <filter id="filter0_d_4025_4631" x="3.71585" y="10.5557" width="50.1957" height="50.1957" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dx="4" dy="4" />
                    <feGaussianBlur stdDeviation="4" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_4025_4631" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_4025_4631" result="shape" />
                </filter>
            </defs>
        </svg>
    );

    return (
        <Modal
            blockScroll={false}
            aria-labelledby="wd40-modal-title"
            aria-describedby="wd40-modal-description"
            open={showModalIndex === index || (onloadmodal && modalAlreadyShown === undefined )}
            onEscKeyDown={() => setShowModal(index)}
            onClose={() => {
                if (modalAlreadyShown === undefined && onloadmodal && onloadmodal !== null) {
                    cookies.set(`modal-${index}`, true, { path: '/' });
                }
                setShowModal(false)
                setModalIndex && setModalIndex(-3)
            }}
            closeIcon={closeIcon}
            classNames={
                {
                    modal: styles.modal,
                    closeIcon: styles.closeIcon,
                    closeButton: styles.closeButton,
                    overlay: styles.modalOverlay,
                    modalContainer: styles.modalContainer,
                }
            }
            center={true}
            {...props}
        >
            {children}
        </Modal>
    )
}

export default QuickModal;
