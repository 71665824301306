import React, { memo } from 'react';
import Imgix from "react-imgix"
import cn from "classnames";
import styles from '../styles/scss/components/usfBanner.module.scss';
import { supSub } from "../services/util";

function USFBanner({ data, option }) {
  const { title, brief, image } = data[option];
  const Banner = ({ title, brief, image }) => (
    <div className={cn(styles.usfBanner)}>
      <Imgix
        sizes='326px'
        src={image.url}
        className={cn(styles.usfBanner_mainImage)}
        htmlAttributes={{ alt: '' }}
      />
      <p className={cn(styles.usfBanner_textElement)}>
        {title}<br />
        <div
          className={cn(styles.usfBanner_textElement_details)}
          dangerouslySetInnerHTML={{
            __html: supSub(brief),
          }} />
      </p>
    </div>
  )
  return <Banner title={title} brief={brief} image={image} />
};

export default memo(USFBanner);