import React from "react"
import Imgix from "react-imgix"
import { Link } from "gatsby"
import Rating from "../components/rating"
import ProductButtons from "./newProductButtons"
import { supSub } from "../services/util"
import styles from "../styles/scss/components/cardProduct.module.scss"

import "lazysizes";
import "lazysizes/plugins/parent-fit/ls.parent-fit";

const newLineTags = { multiUse: "WD-40® Multi-Use ", specialist: "WD-40 Specialist® ", bike: "WD-40 Specialist® Bike" }

const CardProduct = ({
    key,
    title,
    image,
    children,
    product,
    withoutAnchor,
    slug,
    alternativeLook,
}) => {
    // Memos
    const customDisplayName = React.useMemo(() => {
        let out = title ? title : product.displayName

        if (product.productCategory && product.productCategory.slug) {
            switch (product.productCategory.slug) {
                default:
                case "multi-use": {
                    out = out.replace(
                        newLineTags.multiUse,
                        `${newLineTags.multiUse}\n`
                    )
                    break
                }
                case "specialist": {
                    out = out.replace(
                        newLineTags.specialist,
                        `${newLineTags.specialist}\n`
                    )
                    break
                }
                case "bike": {
                    out = out.replace(
                        newLineTags.bike,
                        `${newLineTags.bike}\n`
                    )
                    break
                }
            }
        }

        out = supSub(out)
        return out
    }, [title, product])

    const productImageSrc = React.useMemo(() => {
        return image ? image : product.listImage.url;
    }, [image, product])

    const baseStyles = React.useMemo(() => {
        const altLook = alternativeLook ? styles.alternativeLook : "";
        const defaultStyling = {
            mainContainer: {
                a: styles.CardProductMainContainer,
                b: altLook
            },
            content: styles.boxContent,
            h3: styles.CardTitle,
            productButtons: styles.CustomCardProductButtons
        }
        const withoutAnchorStyling = {
            mainContainer: {
                a: styles.CardProductMainContainerWithoutAnchor,
                b: altLook
            },
            content: styles.ratedboxContent,
            h3: styles.RatedCardTitle,
            productButtons: styles.CustomCardProductButtonsWithoutAnchor
        }



        return withoutAnchor ? withoutAnchorStyling : defaultStyling;
    }, [withoutAnchor])

    return (
        <div key={key} className={`${baseStyles.mainContainer.a} ${baseStyles.mainContainer.b}`}>
            <div className={styles.CardProductMainContainerShadow}>
                <div className={styles.ImageContainer}>
                    <Link to={`/products/${slug}/`} aria-label="Product Detail">
                        <Imgix
                            key={image}
                            src={productImageSrc}
                            width={325}
                            className="lazyload"
                            htmlAttributes={{
                                alt: title ? title : product.displayName
                            }}
                            attributeConfig={{
                                src: "data-src",
                                srcSet: "data-srcset",
                                sizes: "data-sizes",
                            }}
                        />
                    </Link>
                </div>
                <div className={baseStyles.content}>
                    <Link to={`/products/${slug}/`}>
                        <h3 className={baseStyles.h3} dangerouslySetInnerHTML={{ __html: customDisplayName }} />
                    </Link>
                    {
                        withoutAnchor ?
                            <Rating
                                value={product.reviewStats || 0}
                                bold
                                noPadding
                                newStyle
                            />
                            :
                            children
                    }
                    <ProductButtons
                        className={baseStyles.productButtons}
                        product={product}
                        verticalButtons={true}
                        withoutAnchor={withoutAnchor}
                        displayName="Where to buy"
                    />
                </div>
            </div>
        </div>
    )
}

export default React.memo(CardProduct)
