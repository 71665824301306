import React, { Component } from 'react';
import styles from '../styles/scss/components/cardDetailed.module.scss';
import Button from './button';

export default class Index extends Component {

    render() {
        const { title, image, link, children } = this.props;
        return (
            <div className={styles.boxWrap}>
                <div className={styles.boxWrapImage}>
                    <div style={{ backgroundImage: `url(${image})` }} className={styles.boxImage} />
                </div>
                <div className={styles.boxContent}>
                    <div className={styles.shadow}>
                        <h3 className={styles.cardTitle}>{title}</h3>
                        {children}
                        <Button
                            text="READ MORE"
                            url={link}
                            className={styles.button}
                            newBlue
                            pdf
                            rel="noopener noreferrer"
                        />
                    </div>
                </div>
            </div>
        );
    }
}
