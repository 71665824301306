import React from 'react';
import styles from '../styles/scss/components/heroSimple.module.scss';
import Imgix from "react-imgix"

const simpleHero = (props) => {
    const { image, mobileimage = false, title = 'WD40 Hero', isnew = false } = props
    return (
        <>
            {
                isnew ?
                    <div className={styles.heroContainer} {...props}>
                        {
                            mobileimage &&
                            <Imgix
                                sizes='100vw'
                                alt={title}
                                src={mobileimage}
                                className={styles.heroContainer_imageMobile}
                                htmlAttributes={{alt: title}}
                            />
                        }
                        <Imgix
                            sizes='100vw'
                            alt={title}
                            src={image}
                            className={styles.heroContainer_imageDesktop}
                            htmlAttributes={{alt: title}}
                        />
                    </div>
                    :
                    <div className={styles.heroContainerOld}>
                        <div
                            style={{ backgroundImage: `url(${image})` }}
                            className={styles.heroContainerImage} />
                    </div>
            }
        </>


    );
};

export default simpleHero;