import React from "react"
import Imgix from "react-imgix"
import cn from "classnames"
// Styles
import styles from "../styles/scss/components/cardVideo.module.scss"
import CustomModal from "./customModal"

import "lazysizes";
import "lazysizes/plugins/parent-fit/ls.parent-fit";

const CardVideo = ({
    youtubeVideoId,
    image,
    title,
    containerClass,
    imageClass,
    titleClass,
    id,
    customThumbnail,
}) => {
    // States
    const [openVideo, setOpenVideo] = React.useState(false)

    // Handlers
    const handleClick = () => {
        setOpenVideo(true)
    }

    const backgroundImg = customThumbnail !== "" ? customThumbnail : image

    return (
        <div id={id} className={cn(styles.container, containerClass)}>
            {/* Image */}
            <div className={styles.imageBg}>
                <Imgix
                    src={backgroundImg}
                    height={376}
                    width={672}
                    className="lazyload"
                    attributeConfig={{
                        src: "data-src",
                        srcSet: "data-srcset",
                        sizes: "data-sizes",
                    }}
                    htmlAttributes={{alt: ''}}
                />
            </div>
            <div
                style={{
                    backgroundImage: `linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(0, 0, 0, 0.8) 73.88%)`,
                }}
                className={cn(styles.image, imageClass)}
            >

                {youtubeVideoId && (
                    <button className={styles.buttonStyle} href="#" onClick={handleClick} aria-label="play">
                        <div className={styles.circlePlay}>
                            <i className="fa fa-play" />
                        </div>
                    </button>
                )}
            </div>

            {/* Title */}
            {title && (
                <p className={titleClass || styles.defaultTitle}>{title}</p>
            )}

            {/* Modal */}
            {youtubeVideoId && (
                <CustomModal
                    openVideo={openVideo}
                    setOpenVideo={setOpenVideo}
                    youtubeVideoId={youtubeVideoId}
                />
            )}
        </div>
    )
}

export default React.memo(CardVideo)
