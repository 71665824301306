import React from 'react';
import cn from "classnames";
import styles from '../styles/scss/components/courses.module.scss';
import Button from '../components/button';
import Imgix from "react-imgix"
import { supSub } from "../services/util";

const Courses = ({ data, courseKey, toggleUse }) => {
    const PlusIcon = () => (
        <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M8.95215 0.756836L8.95215 2.25684L8.95215 6.20938L12.9047 6.20938L14.4047 6.20938L14.4047 9.20938L12.9047 9.20938L8.95215 9.20938L8.95215 13.1619L8.95215 14.6619H5.95215L5.95215 13.1619L5.95215 9.20938L1.9996 9.20938L0.499601 9.20938L0.499601 6.20938L1.9996 6.20938L5.95215 6.20938L5.95215 2.25684L5.95215 0.756836H8.95215Z" fill="#1C3687" />
        </svg>

    )
    const LessIcon = () => (
        <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M14.5439 2.12132L13.4833 3.18198L9.85907 6.80619L13.4833 10.4304L14.5439 11.4911L12.4226 13.6124L11.362 12.5517L7.73775 8.92751L4.11354 12.5517L3.05288 13.6124L0.931559 11.4911L1.99222 10.4304L5.61643 6.80619L1.99222 3.18198L0.931558 2.12132L3.05288 -5.0229e-07L4.11354 1.06066L7.73775 4.68487L11.362 1.06066L12.4226 -9.27259e-08L14.5439 2.12132Z" fill="#1C3687" />
        </svg>
    )
    return (
        <div className={cn(styles.coursesWrapper)}>
            {
                data && data.map((course, key) => {

                    return (
                        <div key={key}>
                            <div className={cn(styles.courseWrapper)}>
                                <Imgix
                                    sizes='100vw'
                                    src={course.image.url}
                                    className={styles.courseWrapper_general_imageContainer_imageClass}
                                    htmlAttributes={{alt: ''}}
                                />
                                <Imgix
                                    sizes='100vw'
                                    src={course.imageMobile.url}
                                    className={styles.courseWrapper_general_imageContainer_imageClass_mobile}
                                    htmlAttributes={{alt: ''}}
                                />
                                <div className={cn(styles.courseWrapper_general)}>
                                    <p className={cn(styles.courseWrapper_general_title)}>
                                        {course.title}
                                    </p>
                                    <div
                                        className={cn(styles.courseWrapper_general_main)}
                                        dangerouslySetInnerHTML={{
                                            __html: supSub(course.description),
                                        }}
                                    />
                                    <div className={cn(styles.courseWrapper_general_readmore)}>
                                        <div
                                            aria-hidden
                                            role="button"
                                            onKeyDown={() => { toggleUse(key) }}
                                            onClick={() => { toggleUse(key) }}
                                            className={cn(styles.courseWrapper_general_readmore_text)}>
                                            {
                                                courseKey === key ? `SEE LESS` : `SEE MORE`
                                            }
                                            {
                                                courseKey === key ?
                                                    <LessIcon /> : <PlusIcon />
                                            }
                                        </div>
                                    </div>
                                    {
                                        courseKey !== key ?
                                            <div className={cn(styles.registerBasicWrapper)}>
                                                <p className={cn(styles.registerBasicWrapper_price)}>
                                                    {course.price}
                                                </p>
                                                <Button
                                                    text="Register now"
                                                    url={course.registrationLink}
                                                    className={styles.registerBasicWrapper_button}
                                                    target="_blank"
                                                    newBlue
                                                    rel="noopener noreferrer"
                                                />
                                            </div>
                                            :
                                            <div className={cn(styles.registerBasicWrapper_blank)}></div>
                                    }

                                </div>
                            </div>
                            <div
                                className={courseKey === key ? cn(styles.courseWrapper_detailsActive) : cn(styles.courseWrapper_details)}
                            >
                                <div className={cn(styles.courseWrapper_detailsActive_head)}>
                                    <p className={cn(styles.courseWrapper_detailsActive_title)}>
                                        {course.detailsTitle}
                                    </p>
                                </div>
                                <div
                                    className={cn(styles.courseWrapper_detailsActive_text)}
                                    dangerouslySetInnerHTML={{
                                        __html: supSub(course.details),
                                    }}
                                />
                                <div className={cn(styles.registerDesktopWrapper)}>
                                    <p className={cn(styles.registerDesktopWrapper_title)}>
                                        {course.offertitle}
                                    </p>
                                    <p className={cn(styles.registerDesktopWrapper_price)}>
                                        {course.price}
                                    </p>
                                    <Button
                                        text="Register now"
                                        url={course.registrationLink}
                                        className={styles.registerDesktopWrapper_button}
                                        target="_self"
                                        newBlue
                                        rel="noopener noreferrer"
                                    />
                                </div>
                            </div>

                            <div className={cn(styles.registerWrapper)}>
                                <p className={cn(styles.registerWrapper_title)}>
                                    {course.offertitle}
                                </p>
                                <p className={cn(styles.registerWrapper_price)}>
                                    {course.price}
                                </p>
                                <Button
                                    text="Register now"
                                    url={course.registrationLink}
                                    className={styles.registerWrapper_button}
                                    target="_self"
                                    newBlue
                                    rel="noopener noreferrer"
                                />
                            </div>
                        </div>
                    )
                })
            }
        </div>
    );
}

export default React.memo(Courses);