import React, { Component } from 'react';
import styles from '../styles/scss/components/rating.module.scss';
import cn from 'classnames';

export default class Index extends Component {
    percentToRatingConverter(value) {
        let score = 0;
        let stars = 0;
        if (value) {
            score = parseInt(value);
            stars = value;
        }
        return { score, stars };
    }
    render() {
        const { value, text, bold, noPadding, spacing, hideText, newStyle } = this.props;

        let rating = 0
        if (typeof value == 'object') {
            rating = this.percentToRatingConverter(value.averageRating);
        } else {
            rating = this.percentToRatingConverter(value);
        }

        return (
            <div className={cn(styles.rating, { [styles.noPadding]: noPadding, [styles.spacing]: spacing, [styles.newStyle]: newStyle })}>
                {Array(5)
                    .fill()
                    .map((x, i) => (
                        <i key={i} className={`fa fa-star${rating.stars > i ? '' : '-o'}`} />
                    ))}
                {!hideText && (
                    <span style={{ fontWeight: bold ? 'bold' : 'normal' }}>
                        ({rating.stars > 0.0 && (rating.stars.toFixed(2))}) {value ? value.totalReviewCount : 0}
                        {text && ` ${text}`}
                    </span>
                )}
            </div>
        );
    }
}
