import React, { Component } from 'react'
import NewLayout from '../layout/newLayout'
import styles from '../styles/scss/pages/training.module.scss'
import PageContents from '../components/pageContents'
import SEO from "../components/seo"
import cn from "classnames"
import HeroSimple from '../components/heroSimple';
import Courses from '../components/courses';
import AdBlock from "../components/adBlock";
import USFBanner from '../components/usf-banner';

export default class Training extends Component {

	constructor(props, context) {
		super(props, context)
		this.state = {
			courseKey: false,
		};
		this.toggleUse = this.toggleUse.bind(this);
	}

	toggleUse = (key) => {
		const { courseKey } = this.state
		this.setState({ courseKey: key === courseKey ? false : key });
	}
	render() {
		const { page, courses, banners } = this.props.pageContext
		const { courseKey } = this.state || 'allClosed'
		return (
			<NewLayout>
				{page.seo.title &&
					<h1 style={{ display: 'none' }}>
						{page.seo.title}
					</h1>
				}
				<SEO page={page} />
				{(page.heroDesktop && page.heroMobile) &&
					<HeroSimple
						style={{'marginBottom': '10px'}}
						isnew="true"
						image={page.heroDesktop.url}
						mobileimage={page.heroMobile.url}
						title={page.heroDesktop.title}
					/>
				}
				<div className={cn(styles.container)}>
					<PageContents content={page.content} />
				</div>
				<div className={cn(styles.containerSecondary)}>
					<div className={cn(styles.divider)}></div>
					<Courses data={courses} courseKey={courseKey} toggleUse={this.toggleUse} />
				</div>
				<USFBanner data={banners} option={0}/>
				{
					page.adBlocks &&
					<AdBlock data={page.adBlocks} />
				}
			</NewLayout>
		)
	}
}
