import React from 'react';
import Imgix from "react-imgix"
// Components
import Button from './button';
import classnames from 'classnames';

// Styles
import styles from '../styles/scss/components/cardImage.module.scss';

import "lazysizes";
import "lazysizes/plugins/parent-fit/ls.parent-fit";

const CardImage = ({
    title,
    image,
    link,
    children,
    className
}) => {

    return (
        <div className={classnames(className, styles.boxWrap)}>
            {/* Image */}
            <div
                className={styles.boxWrapImage}
            >
                <Imgix
                    src={image}
                    alt=""
                    width={340}
                    className={`lazyload ${styles.cardImage}`}
                    attributeConfig={{
                        src: "data-src",
                        srcSet: "data-srcset",
                        sizes: "data-sizes",
                    }}
                    htmlAttributes={{alt: ''}}
                />
            </div>

            {/* Content */}
            <div
                className={styles.boxContent}
            >
                <h3
                    className={styles.cardTitle}
                >
                    {title}
                </h3>
                {children}
                <Button
                    text="READ MORE"
                    url={link}
                    className={styles.button}
                    target="_blank"
                    newBlue
                    pdf
                    rel="noopener noreferrer"
                />
            </div>
        </div>
    );
}

export default React.memo(CardImage);
